.box.job {
  min-height: 6.4rem;
  padding: 1rem 1.2rem 1.6rem 1.6rem;
  margin-bottom: 0.6rem;
  .title {
    margin-bottom: 2rem;
  }
  .title {
    color: get-color('black');
  }
  .subtitle {
    color: get-color(bluish-grey);
  }
  &.is-opened {
    padding: 1rem 1.2rem 0 1.6rem;
  }
  &.is-closed {
  }
  .divider {
    &.is-fullwidth {
      margin: 0 -1.2rem 0 -1.6rem !important;
    }
  }
}

.job {
  a {
    color: get-color(light-blue-grey);
  }
  .meta {
    margin-top: 0.7rem;
    > * {
      color: get-color(bluey-grey);
      font-size: 1.3rem;

      line-height: 1.1em;
      //* {
      //  line-height:1.1em;
      //}
    }
  }
}
.has-job-status-deleted,
.has-job-status-error {
  &.is-closed {
    border-color: get-color(error);
    &:focus,
    &:hover {
      @include active-shadow(get-color(error));
    }
  }
}