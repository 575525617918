.default-layout {
  .navbar {
    background-color: get-color(dark-sky-blue);
    color: get-color-contrast(dark-sky-blue);
    height: $navbar-height;
    z-index: 1000;

    .navbar-brand {
      height: $navbar-height;
    }
    a.brand {
      font-size: 2.4rem;
      font-weight: 500;
      color: get-color-contrast(dark-sky-blue);
      padding-left: 1.6rem;
      img {
        margin-right: 1.6rem;
        max-height: 3.2rem;
      }
      &:hover {
        background-color: transparent;
      }
    }

    .navbar-item:hover:not(.brand) {
      background-color: darken(get-color(dark-sky-blue), 10) !important;
      transition: width 0.5s ease, background-color 0.5s ease;
    }
    .navbar-item.active::after {
      position: absolute;
      content: '';
      bottom: 0;
      right: 1.4rem;
      left: 1.4rem;
      border-bottom: 0.4rem solid rgba(255, 255, 255, 0.7);
      //transition: 5s all ease;
    }

    .navbar-item img {
      border: 0.1rem solid transparent;
      padding: 0.3rem;
      border-radius: 0.4rem;
      max-height: initial;
    }

    .navbar-item .icon {
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }
  }
}
