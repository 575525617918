.default-layout {
  .title {
    color: get-color('dark');
    font-weight: 500;
  }
  .title.is-head {
    font-size: 3.2rem;
    //font-weight:500;
    color: get-color('dark-grey-blue');
    line-height: 1.38em;
  }
  .subtitle.is-1,
  .title.is-1 {
    font-size: 2.2rem;
    //font-weight:500;
    color: get-color('black');
    line-height: 3rem;
    .tag {
      margin-top: -0.5rem;
    }
  }
  .subtitle.is-2,
  .title.is-2 {
    font-size: 1.7rem;
    //font-weight: 500;
    line-height: 1.47em;
  }
  .subtitle.is-3,
  .title.is-3 {
    font-size: 1.5rem;
    //font-weight: 500;
    line-height: 1.33em;
  }
  .subtitle.is-4 {
    * {
      line-height: 1.3em;
    }
  }
}
